import ApiDefault from "../ApiDefault";

const PATH = 'db/property';

export default class Property extends ApiDefault {
  async delete(ids) {
    return this.remove(PATH, { ids })
  }

  async create(data) {
    return this.post(PATH, data);
  }

  async getProperty(id) {
    return this.get(PATH + "/" + id);
  }

  async update(id, data) {
    return this.put(PATH + "/" + id, data);
  }

  async send(id) {
    return this.get(PATH + "/" + id + "/send");
  }

  async move(ids) {
    return this.post(PATH + "/move", { ids });
  }

  async deleteImages(data) {
    return this.remove(PATH + "/images", data)
  }

  async uploadImages(id, images) {
    const formData = new FormData();
    Array.from(images).forEach(image => {
      formData.append("images[]", image);
    })
    id && formData.append("id", id);

    return this.post(PATH + "/images", formData);
  }

  async complaint(id, reason) {
    return this.put(PATH + "/" + id + "/complaint", { reason });
  }
}