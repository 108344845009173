import { styled } from "@mui/system";
import { Tooltip, tooltipClasses } from "@mui/material";
import React from "react";

export function ArrowTooltip({ children, showTooltip = true, ...props }) {
  return showTooltip ? <StyledTooltip {...props}>{children}</StyledTooltip> : children
}

const StyledTooltip = styled(({ children, className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }}>{children}</Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.black,
    fontSize: '14px',
    color: theme.palette.text.white
  },
}));