import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { calendarApi } from "helpers";

const name = "calendar";
const initialState = [];
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

export const calendarActions = { ...slice.actions, ...extraActions };
export const calendarReducer = slice.reducer;

function createReducers() {
  return {};
}

function createExtraActions() {
  return {
    create: create(),
    all: all(),
    delete: deleteCalendar(),
  };

  function create() {
    return createAsyncThunk(`${name}/create`, async (data) => {
      return await calendarApi.create(data)
    });
  }

  function all() {
    return createAsyncThunk(`${name}/all`, async (id) => await id ? calendarApi.client(id) : calendarApi.all());
  }

  function deleteCalendar() {
    return createAsyncThunk(`${name}/delete`, async (data) => await calendarApi.delete(data));
  }

}

function createExtraReducers() {
  return {
    ...create(), ...all(), ...deleteCalendar()
  };

  function create() {
    const { fulfilled } = extraActions.create;
    return {
      [fulfilled]: (state, action) => {
        const calendar = action.meta.arg;
        const id = action.payload.id;
        calendar.id = action.payload.id;
        calendar.dateFrom = calendar.dateFrom.toJSON();
        calendar.dateTo = calendar.dateTo.toJSON();
        const newState = state.filter(calendar => calendar.id !== id)
        newState.push(calendar);

        return newState;
      },
    };
  }

  function all() {
    const { fulfilled } = extraActions.all;
    return {
      [fulfilled]: (state, action) => {
        return action.payload.result;
      },
    };
  }

  function deleteCalendar() {
    const { fulfilled } = extraActions.delete;
    return {
      [fulfilled]: (state, action) => {
        const id = action.meta.arg;

        return state.filter(calendar => calendar.id !== id)
      },
    };
  }
}
