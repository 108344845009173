import { Box, Dialog, Typography } from "@mui/material";
import * as React from "react";
import { SubmitResetGroup } from "../buttons";
import { CloseDialog } from "./CloseDialog";
import { useTranslation } from "react-i18next";

export function DeleteDialog({
    addLabel = null,
    cancelLabel = null,
    handleClose,
    loading,
    open,
    onSubmit,
    onCancel,
    subTitle,
    title
  }) {
  const { t } = useTranslation(["translation", "form"]);

  return (<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
    <CloseDialog handleClose={handleClose} />
    <Box sx={{ margin: "46px 148px 44px 148px", alignItems: "center", textAlign: "center" }}>
    <Typography variant="h2" sx={{ fontWeight: "bold", mb: 2.5 }}>
        {title}
      </Typography>
      <Typography variant="h4" sx={{ mt: 2.25, mb: 6.25, color:"newGrey.dark" }}>
        {subTitle}
      </Typography>
      <img
        src="/images/agents/delete-photo.svg"
        alt="delete"
        style={{ height: "160px", width: "240px", borderBottom: "1px solid #141414", marginBottom:"36px" }}
      />
      <form onSubmit={(e) => {
        e.preventDefault();
        onSubmit()
      }}>
        <SubmitResetGroup 
          addLabel={addLabel !== null ? addLabel : t('delete')} 
          cancelLabel={cancelLabel !== null ? cancelLabel : t('cancelTitle')}
          handleClose={() => {
          onCancel ? onCancel() : handleClose()
        }} loading={loading} />
      </form>
    </Box>
  </Dialog>);
}
