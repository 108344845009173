import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { departmentApi } from "helpers";

const name = "department";
const initialState = [];
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

export const departmentActions = { ...slice.actions, ...extraActions };
export const departmentReducer = slice.reducer;

function createReducers() {
  return {
    updateAgent, replaceDepartments, replaceAgent, changeName,
  };

  function changeName(state, { payload }) {
    const { id, title } = payload;

    const department = state.find(department => department.id === id);
    department.title = title;

    return state;
  }

  function updateAgent(state, { payload }) {
    state.map(department => {
      department.agents = department.agents.map(agent => {
        if (agent.id === payload.id) {
          agent = payload;
        }

        return agent;
      })
      return department;
    })
  }

  function replaceDepartments(state, { payload }) {
    state.splice(payload.hoverIndex, 0, state.splice(payload.dragIndex, 1)[0]);
  }

  function replaceAgent(state, { payload }) {
    const { dragIndex, hoverIndex, item, departmentId, toHead } = payload;
    const from = state.find(department => department.id === item.departmentId);
    const to = state.find(department => department.id === departmentId);

    let removeItem;
    if (item.isHead) {
      removeItem = from.head;
      from.head = null;
    } else {

      removeItem = from.agents.find((agent) => {
        if (agent.id === item.id) {
          from.agents.splice(dragIndex, 1)
        }

        return agent.id === item.id;
      })
    }

    if (removeItem) {
      if (toHead) {
        if (to.head) {
          to.agents.splice(hoverIndex, 0, to.head);
        }
        to.head = removeItem;
      } else {
        to.agents.splice(
          departmentId === item.departmentId && !item.isHead && hoverIndex > item.index ? hoverIndex - 1 : hoverIndex,
          0,
          removeItem
        );
      }
    }
  }
}

function createExtraActions() {
  return {
    create: create(),
    all: all(),
    updateOrder: updateOrder(),
    updateAll: updateAll(),
    deleteDepartment: deleteDepartment(),
    updateName: updateName()
  };

  function create() {
    return createAsyncThunk(`${name}/create`, async (data, thunkAPI) => {
      const state = thunkAPI.getState();

      const department = {
        title: data.title, head: data.head?.id, agents: [], order: state.department.length + 1
      };
      data.agents.map(agent => department.agents.push(agent.id));

      return await departmentApi.create(department)
    });
  }

  function all() {
    return createAsyncThunk(`${name}/all`, async () => await departmentApi.all());
  }

  function updateOrder() {
    return createAsyncThunk(`${name}/updateOrder`, async (data, thunkAPI) => {
      const state = thunkAPI.getState();
      const newOrder = {};
      state.department.map((department, index) => {
        newOrder[department.id] = index + 1;
        return newOrder;
      })

      return await departmentApi.updateOrder(newOrder)
    });
  }

  function updateAll() {
    return createAsyncThunk(`${name}/updateDepartments`, async (data, thunkAPI) => {
      const state = thunkAPI.getState();
      const newDepartments = {};
      state.department.forEach(department => {
        newDepartments[department.id] = {};
        newDepartments[department.id].agents = {};
        newDepartments[department.id].head = department.head?.id;
        department.agents.forEach((agent, index) => {
          newDepartments[department.id].agents[agent.id] = index + 1;
        })
      })

      return await departmentApi.updateAll(newDepartments)
    });
  }

  function deleteDepartment() {
    return createAsyncThunk(`${name}/delete`, async (data) => await departmentApi.delete(data));
  }

  function updateName() {
    return createAsyncThunk(`${name}/update-name`, async (data) => await departmentApi.updateName(data));
  }

}

function createExtraReducers() {
  return {
    ...create(), ...all(), ...deleteDepartment()
  };

  function create() {
    const { fulfilled } = extraActions.create;
    return {
      [fulfilled]: (state, action) => {
        const department = action.meta.arg;
        department.id = action.payload.id;

        state.push(department);
      },
    };
  }

  function all() {
    const { fulfilled } = extraActions.all;
    return {
      [fulfilled]: (state, action) => {
        localStorage.setItem('departmentsTotal', JSON.stringify(action.payload.result.length + 1));

        return action.payload.result;
      },
    };
  }

  function deleteDepartment() {
    const { fulfilled } = extraActions.deleteDepartment;
    return {
      [fulfilled]: (state, action) => {
        const id = action.meta.arg;

        return state.filter(department => department.id !== id)
      },
    };
  }
}
