import { MenuItem, Typography } from "@mui/material";
import React from 'react';
import { useTranslation } from "react-i18next";
import { ContextMenu } from "./ContextMenu";

export function SortMenu({ anchor, anchorHandler, setSort }) {
  const { t } = useTranslation(["translation", "form"]);

  return <ContextMenu
    anchorEl={anchor}
    open={Boolean(anchor)}
    onClose={() => anchorHandler(null)}
  >
    <MenuItem onClick={() => {
      anchorHandler(null);
      setSort({ field: "date", direction: "asc" })
    }}>
      <Typography variant="text">{t('createdAtAsc')}</Typography>
    </MenuItem>
    <MenuItem onClick={() => {
      anchorHandler(null);
      setSort({ field: "date", direction: "desc" })
    }}>
      <Typography variant="text">{t('createdAtDesc')}</Typography>
    </MenuItem>
    <MenuItem onClick={() => {
      anchorHandler(null);
      setSort({ field: "price", direction: "asc" })
    }}>
      <Typography variant="text">{t('priceAsc')}</Typography>
    </MenuItem>
    <MenuItem onClick={() => {
      anchorHandler(null);
      setSort({ field: "price", direction: "desc" })
    }}>
      <Typography variant="text">{t('priceDesc')}</Typography>
    </MenuItem>
  </ContextMenu>
}