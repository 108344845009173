import ApiDefault from "./ApiDefault";

const PATH = 'crm/apartment';

export default class Apartment extends ApiDefault {

  async all() {
    return this.get(PATH);
  }

  async clientAll(clientId) {
    return this.get(PATH + "/client/" + clientId);
  }

  async clientSelected(clientId) {
    return this.get(PATH + "/client/selected/" + clientId);
  }

  async getApartment(ids) {
    return this.post(PATH + "/list", {ids});
  }

  async create(data) {
    return this.post(PATH, data);
  }

  async ownerTypes() {
    return this.get(PATH + "/info/owner-types");
  }

  async repairTypes() {
    return this.get(PATH + "/info/repair-types");
  }

  async complaintReasons() {
    return this.get(PATH + "/info/complaint-reasons");
  }

  async getObjectByLink(link) {
    return this.post(PATH + "/link", { link })
  }

  async deleteFromBoard(apartments, board, client) {
    return this.post(PATH + "/delete-from-board", { apartments, board, client })
  }

  async copyToBoard(data) {
    return this.post(PATH + "/copy-to-board", data)
  }

  async deleteImages(data) {
    return this.remove(PATH + "/images", data)
  }

  async uploadImages(id, images) {
    const formData = new FormData();
    Array.from(images).forEach(image => {
      formData.append("images[]", image);
    })
    id && formData.append("id", id);

    return this.post(PATH + "/images", formData);
  }
}