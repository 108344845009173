import { ReactComponent as LogoIcon } from "../assets/logo.svg";
import React from "react";
import { Box } from "@mui/material";
import { NavLink } from "react-router-dom";

export function Logo(props) {
  return (
    <Box style={props.style} sx={{"& a": {color: (theme) => theme.palette.mode === "dark" ? "#F5F5F5" : "#141414"}}}>
        <NavLink to="/"><LogoIcon alt="logo" style={{ maxHeight: "100%" }} /></NavLink>
    </Box>
  );
}
