import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography, } from "@mui/material";

export function TableBlock({
  headerChild,
  footerChild,
  logoPath,
  rows = [],
  subtitle,
  title,
}) {
  return (
    <Grid
      item
      xs={12}
      sx={{
        mb: {xs: 2.8, xl: 3},
        padding: "18px 24px",
        backgroundColor: "newGrey.veryLight",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: { xs: "48px", xl: "60px" },
        }}
      >
        <Box>
          <Typography variant="h3" sx={{ fontWeight: "bold", mt: 1 }}>
            {title}
          </Typography>
          <Typography
            variant="h5"
            sx={{ fontSize: { xs: "14px", xl: "16px" } }}
          >
            {subtitle}
          </Typography>
          {headerChild}
        </Box>
        <img
          src={logoPath ? logoPath : "/images/icon/scheme.svg"}
          height={"60px"}
          alt="logo"
        />
      </Box>
      <TableContainer sx={{}}>
        <Table>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell
                  sx={{
                    width: "200px",
                    p: { xs: "16px 20px 16px 0", xl: "20px 24px 20px 0" },
                    fontSize: { xs: "14px", xl: "16px" },
                    color:"newGrey.dark"
                  }}
                >
                  {row.name}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    p: { xs: "16px 20px 16px 0", xl: "20px 24px 20px 0" },
                    fontSize: { xs: "14px", xl: "16px" },
                    color:"dark"
                  }}
                  align="left"
                >
                  {row.value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {footerChild && <Box sx={{ margin: "30px 0 0 20px" }}>{footerChild}</Box>}
    </Grid>
  );
}
