import ApiDefault from "./ApiDefault";

const PATH = 'crm/department';

export default class Department extends ApiDefault {
  async create(data) {
    return this.post(PATH, data);
  }

  async all() {
    return this.get(PATH);
  }

  async updateOrder(data) {
    return this.put(PATH + "/update-order", data);
  }

  async updateAll(data) {
    return this.put(PATH + "/update-all", data);
  }

  async updateName(data) {
    return this.put(PATH + "/update-name", data);
  }

  async delete(id) {
    return this.remove(PATH + "/" + id);
  }
}