import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Button } from "./Button";
import React from 'react';

export const AddButton = ({ onClick, disabled }) => {
  return (
    <Button className="add-button" onClick={onClick} disabled={disabled}>
      <AddOutlinedIcon fontSize={"inherit"} />
    </Button>
  );
};
