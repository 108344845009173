import { FormControl, Typography } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

export const LabeledAsyncSelect = ({ async, field, formik, label, placeholder, required }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(null);
  const loading = open && options === null;
  const { t } = useTranslation(["translation", "form"]);

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    async().then(({ result }) => {
      setOptions(result.map(row => {
        row.label = t(row.label);
        return row;
      }))
    });
  }, [loading, async]);

  return <FormControl fullWidth>
    <Typography className={required && "label"} variant="h4" mt={{ xs: 1.5, md: 3.75 }}>
      {label}
    </Typography>
    <Autocomplete
      fullWidth
      open={open}
      onChange={(e, newValue) =>
        formik.setFieldValue(field, newValue, true)
      }
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      getOptionLabel={(option) => option.label}
      options={options || []}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          error={formik.errors[field] && formik.touched[field]}
          helperText={formik.touched[field] && formik.errors[field]}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            style: {
              padding: 0
            },
          }}
          placeholder={placeholder}
          variant="filled"
        />
      )}
      sx={{ mb: 0.5, mt: 1 }}
      variant="filled"
      value={formik.values[field]}
    />
  </FormControl>
}