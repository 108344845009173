import { Box, Dialog, Grid, Typography, } from "@mui/material";
import { CloseDialog, DatePickerInput, LabeledInput, SubmitResetGroup } from "components";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { calendarActions } from "store";
import * as Yup from "yup";

export default function EventDialog({ open, handleClose, event }) {
  const { t } = useTranslation(["translation", "form"]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('required_field')),
    dateFrom: Yup.date().required(t('required_field')),
    dateTo: Yup.date().required(t('required_field')),
  });

  const fromDate = new Date();
  const minutes = fromDate.getMinutes();
  const roundedMinutes = Math.round(minutes / 30) * 30;
  fromDate.setMinutes(roundedMinutes);
  fromDate.setSeconds(0, 0);

  const formik = useFormik({
    initialValues: event || {
      title: "",
      dateFrom: fromDate,
      dateTo: new Date(fromDate.getTime() + 30 * 60000)
    }, enableReinitialze: true, validateOnChange: false, validationSchema: validationSchema, onSubmit: (values) => {
      setLoading(true);
      const data = { ...values }
      if (event) {
        data.id = event.id;
        data.clientId = event.clientId;
      }

      dispatch(calendarActions.create(data)).then(() => {
        setLoading(false);
        handleClose();
      });
    },
  });
  return (<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
    <CloseDialog handleClose={handleClose} />
    <Box sx={{ margin: "46px 46px 78px 46px" }}>
      <Typography variant="h2" sx={{ fontWeight: "bold" }}>
        {event?.title ? t('eventEdit') : t('eventNew')}
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <LabeledInput
          autoFocus={true}
          field="title"
          formik={formik}
          label={t('title')}
          placeholder={t('titleType')}
          required
        />
        <Grid
          container
          rowSpacing={1}
          columnSpacing={2}
        >
          <Grid item xs={6}>
            <DatePickerInput
              field="dateFrom"
              formik={formik}
              label={t('startTme')}
              isTime={true}
              placeholder={t('chooseStartTme')}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePickerInput
              field="dateTo"
              formik={formik}
              label={t('endTime')}
              isTime={true}
              placeholder={t('chooseEndTme')}
            />
          </Grid>
        </Grid>

        <SubmitResetGroup addLabel={event ? t('save') : t('add')} handleClose={handleClose} loading={loading} />
      </form>
    </Box>
  </Dialog>);
}
