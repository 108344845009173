import axios from "axios";
import { authActions, messageActions, store } from 'store';
import { history } from "../history";
import { t } from "i18next";

export const BACKEND_URL = process.env.REACT_APP_API_HOST;
const API_BASE = BACKEND_URL + '/api/v1/';

axios.create({
  headers: {
    "Content-type": "application/json"
  }
})

export default class ApiDefault {
  async post(path, data, onUploadProgress) {
    return request("POST")(API_BASE + path, data, onUploadProgress);
  }

  async get(path) {
    return request("GET")(API_BASE + path, null);
  }

  async put(path, data) {
    return request("PUT")(API_BASE + path, data);
  }

  async remove(path, data) {
    return request("DELETE")(API_BASE + path, data);
  }
}

function request(method) {
  return (url, body, onUploadProgress) => {
    const options = { headers: authHeader(url), method, url };
    if (body) {
      options.data = body;
    }

    if (onUploadProgress) {
      options.onUploadProgress = onUploadProgress;
    }
    return axios.request(options).then(({ data, status }) => {
      handleSuccess(status);

      return data;
    }).catch(handleResponse);
  }
}

function authHeader(url) {
  const token = authToken();
  const pathToSkipAuth = ['/login', '/presentation/', '/register']
  const isLoggedIn = !!token && !pathToSkipAuth.some(path => url.includes(path));

  return isLoggedIn ? { 'Authorization': `Bearer ${token}` } : {};
}

function authToken() {
  return store.getState().auth.user?.token;
}

function handleResponse(error) {
  if (error.response.status === 401 && authToken()) {
    const logout = () => {
      store.dispatch(authActions.logout());
    };
    logout();
  }

  if (error.response.status === 404) {
    history.navigate('not-found');
  }

  const message = error.response.data?.message || error.response.statusText;
  store.dispatch(messageActions.addError(message));

  return Promise.reject(message);
}

function handleSuccess(status) {
  if (status === 201) {
    store.dispatch(messageActions.addSuccess(t('successCreated')));
  }
}