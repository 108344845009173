import { DialogTitle, IconButton } from "@mui/material";
import { ReactComponent as Close } from "assets/close.svg";
import React from 'react';

export function CloseDialog({ handleClose }) {
  return <DialogTitle>
    <IconButton
      aria-label="close"
      color="text"
      onClick={handleClose}
      sx={{
        position: "absolute",
        right: '1rem',
        top: '1rem',
        height: 48,
        width: 48,
        backgroundColor: 'transparent',
      }}
    >
      <Close width={24} height={24} />
    </IconButton>
  </DialogTitle>
}