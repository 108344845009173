import { Typography } from "@mui/material";
import { PasswordField } from "../passwordField/PasswordField";
import React from 'react';

export const LabeledPassword = (
  {
    field,
    formik,
    label,
    placeholder,
    required,
  }
) => {
  return <>
    <Typography className={required && "label"} variant="h4" mt={{ xs: 1.5, md: 3.75 }} mb={1}>
      {label}
    </Typography>
    <PasswordField
      autoComplete="password"
      error={formik.errors[field] && formik.touched[field]}
      helperText={formik.touched[field] && formik.errors[field]}
      name={field}
      onChange={formik.handleChange}
      placeholder={placeholder}
      variant="filled"
    />
  </>
}