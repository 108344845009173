import { SvgIcon } from "@mui/material";
import FilledInput from "@mui/material/FilledInput";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";
import { ReactComponent as VisibilityOff } from './icons/visibility-off.svg';
import { ReactComponent as Visibility } from './icons/visibility.svg';
import React from 'react';

export function PasswordField(props) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { helperText, ...inputProps } = props;
  return (
    <FormControl fullWidth>
      <FilledInput
        {...inputProps}
        aria-describedby={props.name + "-text"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              <SvgIcon>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </SvgIcon>
            </IconButton>
          </InputAdornment>
        }
        type={showPassword ? "text" : "password"}
        variant="filled"
      />
      <FormHelperText error={props.error} id={props.name + "-text"}>{helperText}</FormHelperText>
    </FormControl>
  );
}
