import { MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useTranslation } from 'react-i18next';

export function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return <StyledSelect
    inputProps={{
      sx: { border: "none", fontSize: "12px", padding: "10px 0" },
    }}
    onChange={handleLanguageChange}
    size="small"
    value={i18n.language}
  >
    <MenuItem key="ua" value="ua">UA</MenuItem>
    <MenuItem key="ru" value="ru">RU</MenuItem>
  </StyledSelect>
}

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.grey.light,
  border: 'none',
  borderRadius: "20px",
  '& fieldset': {
    border: 'none',
  },
  '&:focus fieldset': {
    border: 'none',
  },
}));