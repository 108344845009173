import { Checkbox as MuiCheckbox, } from "@mui/material";
import { ReactComponent as UncheckedIcon } from 'assets/checkbox_uncheked.svg';
import { ReactComponent as CheckedIcon } from 'assets/checkbox_cheked.svg';
import { ReactComponent as IndeterminateIcon } from 'assets/checkbox_indeterminate.svg';

export const Checkbox = ({children, checked, ...props}) => <MuiCheckbox sx={{color: checked ? "unset" : "newGrey.light"}}
  icon={<UncheckedIcon />}
  checkedIcon={<CheckedIcon />}
  indeterminateIcon={<IndeterminateIcon />}
  checked={checked}
  {...props}
>{children}</MuiCheckbox>