import ApiDefault from "../ApiDefault";

const PATH = 'm2/property';

export default class Property extends ApiDefault {
  async getProperty(id) {
    return this.get(PATH + "/" + id);
  }

  async search(data) {
    return this.post(PATH + "/search", data);
  }

  async send(id) {
    return this.get(PATH + "/" + id + "/send");
  }

  async move(ids) {
    return this.post(PATH + "/move", { ids });
  }

  async addressList(name) {
    return this.post(PATH + "/address", { name });
  }

  async complaint(id, reason) {
    return this.put(PATH + "/" + id + "/complaint", { reason });
  }
}