import { FormControl, FormHelperText, ListSubheader, MenuItem, Select, Typography } from "@mui/material";
import React from 'react';

export const LabeledSelect = ({ disabled, field, formik, label, options, placeholder, required = true }) => {
  return <FormControl fullWidth>
    <Typography className={required && "label"} variant="h4" mt={{ xs: 1.5, md: 3.75 }}>
      {label}
    </Typography>
    <Select
      error={formik.errors[field] && formik.touched[field]}
      fullWidth
      disabled={disabled}
      name={field}
      onChange={formik.handleChange}
      placeholder={placeholder}
      sx={{
        mb: 0.5, mt: 1, '& .MuiSelect-select .notranslate::after': placeholder ? {
          content: `"${placeholder}"`, opacity: 0.42,
        } : {},
      }}
      value={formik.values[field]}
      variant="filled"
    >
      {options.map((option, key) => option.value !== undefined
        ? <MenuItem key={key} value={option.value}>{option.name}</MenuItem>
        : <ListSubheader key={key} sx={{ backgroundColor: "grey.main" }}>{option.name}</ListSubheader>
      )}
    </Select>
    {formik.touched[field] && formik.errors[field] && <FormHelperText error>{formik.errors[field]}</FormHelperText>}
  </FormControl>
}