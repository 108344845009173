import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TextButton = ({children, ...props}) => <StyledButton {...props} variant="text">{children}</StyledButton>

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "unset",
  color: theme.palette.dark,
  padding: 0,
  fontSize: "14px",
  lineHeight: "22px",
  [`&:hover`]: {
    backgroundColor: "unset",
    color: theme.palette.primary.dark,
  },
  [`&:disabled`]: {
    backgroundColor: "unset",
    color: theme.palette.newGrey.medium,
  },
}));