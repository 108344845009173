import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Button, FormControlLabel, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { NestedMenuItem } from 'mui-nested-menu';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { departmentActions } from "store";

export function DepartmentAgentSelectorWithCheckbox({ categories, setCategories }) {
  const { t } = useTranslation(["translation", "form"]);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const user = useSelector(store => store.auth.user);
  const { department } = useSelector((store) => store);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    user && dispatch(departmentActions.all());
  }, [user, dispatch])

  useMemo(() => {
    const categories = department.map(department => {
      const result = { id: department.id, name: department.title };
      result.subcategories = [];
      department.head && result.subcategories.push({
        id: department.head.id,
        name: department.head.lastName + " " + department.head.firstName + " " + department.head.middleName,
        checked: true
      })
      department.agents.forEach(agent => {
        if (agent.status === 0) {
          return;
        }
        const fullName = agent.lastName + " " + agent.firstName + " " + agent.middleName;
        result.subcategories.push({ id: agent.id, name: fullName, checked: true });
      });

      return result;
    });
    if (user.isDirector) {
      categories.unshift({
        id: user.id,
        name: t('director'),
        subcategories: [{
          id: user.id,
          email: user.email,
          name: user.fullName,
          checked: true
        }]
      })
    }
    setCategories(categories)
  }, [department, user])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryChange = (categoryId) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) => {
        if (category.id === categoryId || categoryId === 'all') {
          const checked = category.subcategories.every((subcategory) => subcategory.checked);
          return {
            ...category,
            subcategories: category.subcategories.map((subcategory) => ({
              ...subcategory,
              checked: categoryId === 'all' ? !allChecked : !checked,
            })),
          };
        }
        return category;
      })
    );
  };

  const handleSubcategoryChange = (categoryId, subcategoryId) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            subcategories: category.subcategories.map((subcategory) => {
              if (subcategory.id === subcategoryId) {
                return { ...subcategory, checked: !subcategory.checked };
              }
              return subcategory;
            }),
          };
        }
        return category;
      })
    );
  };

  const total = categories.reduce((total, category) => total + category.subcategories.length, 0);
  const checked = categories.reduce((total, category) => total + category.subcategories.filter(subcategory => subcategory.checked).length, 0);
  const allChecked = total === checked;

  return (
    <>
      <Typography variant="text" sx={{fontSize: {xs:"13px", xl:"16px"}, color:"newGrey.dark"}}>{t('agent')}: </Typography>
      <Button
        color="text"
        disabled={!user.isCrmUser}
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        onClick={handleClick}
        variant="text"
        sx={{
          fontWeight: "500", fontSize: {xs:"13px", xl:"16px"}, color:"dark", m:"0", p:"0"
        }}>{allChecked ? t('all') : `${checked} ${t('c')} ${total}`}</Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem sx={{ pl: "12px" }}>
          <FormControlLabel
            label={t('chooseAll')}
            onChange={() => handleCategoryChange('all')}
            control={<Checkbox
              indeterminate={
                categories.some((category) => category.subcategories.some((subcategory) => subcategory.checked)) &&
                !categories.every((category) => category.subcategories.every((subcategory) => subcategory.checked))
              }
              checked={allChecked}
            />}
          />

        </MenuItem>
        {categories.map((category) => (
          !!category.subcategories.length && <Submenu
            key={category.id}
            category={category}
            handleCategoryChange={handleCategoryChange}
            handleSubcategoryChange={handleSubcategoryChange}
            open={Boolean(anchorEl)}
          />
        ))}
      </Menu>
    </>
  );
}

const Submenu = ({ category, handleCategoryChange, handleSubcategoryChange, open }) => {
  return <>
    <NestedMenuItem
      parentMenuOpen={open}
      rightIcon={<ArrowRightIcon/>}
      label={<FormControlLabel
        onChange={() => handleCategoryChange(category.id)}
        control={<Checkbox
          indeterminate={
            category.subcategories.some((subcategory) => subcategory.checked) &&
            !category.subcategories.every((subcategory) => subcategory.checked)
          }
          checked={category.subcategories.every((subcategory) => subcategory.checked)}
        />}
        label={category.name} />}
    >
      {category.subcategories.map((subcategory) => (
        <MenuItem key={subcategory.id}>
          <FormControlLabel
            onChange={() => handleSubcategoryChange(category.id, subcategory.id)}
            control={<Checkbox checked={subcategory.checked} />}
            label={subcategory.name} />
        </MenuItem>
      ))}
    </NestedMenuItem>
  </>
}