import { Box, IconButton } from "@mui/material";
import { NavLink } from "react-router-dom";
import React from 'react';
import { ArrowTooltip } from "components";

export default function MenuItem({ title, to, children }) {
  return (
    <Box sx={{ marginBottom: { xs: "16px", xl: "20px" }, color: "black", "& .active": { color: "white", backgroundColor: "primary.main" } }}>
      <ArrowTooltip title={title} placement="right">
        <IconButton
          sx={{ height: { xs: "36px", xl: "40px" }, width: { xs: "36px", xl: "40px" } }}
          color={'inherit'}
          component={NavLink}
          to={to}
        >
          {children}
        </IconButton>
      </ArrowTooltip>
    </Box>
  );
}
