import { imagesPath } from "config";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import './fonts/Roboto-bold';
import './fonts/Roboto-normal';
import { t } from "i18next";

export const saveReportTablePDF = (dataArray, tableColumnHeaders) => {
  const doc = generateReportTable(dataArray, tableColumnHeaders)
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('ru-Ru');
  doc.save(`report-${formattedDate}.pdf`);
};

export const printReportTablePDF = (dataArray, tableColumnHeaders) => {
  const doc = generateReportTable(dataArray, tableColumnHeaders)
  doc.autoPrint();
  window.open(doc.output('bloburl'), '_blank');
};

const generateReportTable = (dataArray, tableColumnHeaders) => {
  const doc = new jsPDF();

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString('ru-Ru');

  doc.setFont('Roboto', "bold")
    .setFontSize(22)
    .text(t('reports'), 30, 25)
    .setFont('Roboto', "normal")
    .setFontSize(14)
    .text(t('date') + ': ' + formattedDate, 30, 35)

  const tableRows = [];

  dataArray.forEach((data) => {
    tableRows.push([
      data.agent.lastName + " " + data.agent.firstName + " " + data.agent.middleName,
      data.views,
      data.clients,
      data.apartments,
      data.deals,
      data.feeds
    ]);
  });

  doc.autoTable({
    head: [tableColumnHeaders],
    body: tableRows,
    startY: 45,
    styles: {
      font: 'Roboto',
      fontStyle: 'normal',
    }
  });

  return doc;
}

export const printPropertyPDF = (user, property, handleLoad) => {
  handleLoad(true)
  generatePropertyPDF(user, property).then(doc => {
    handleLoad(false)
    doc.autoPrint();
    window.open(doc.output('bloburl'), '_blank');
  });
}

export const savePropertyPDF = (user, property, handleLoad) => {
  handleLoad(true)
  generatePropertyPDF(user, property).then(doc => {
    handleLoad(false)
    doc.save(`property-${property.id}.pdf`);
  });
}

async function generatePropertyPDF(user, property) {
  const doc = new jsPDF();
  const pageHeight = doc.internal.pageSize.height - 20;
  const pageWidth = doc.internal.pageSize.width;

  doc.setFont('Roboto', "normal")
    .setFontSize(14)
    .addImage(user.avatar ? imagesPath + user.avatar : "", 'JPEG', 10, 10, 30, 30)
    .text(user.fullName, 50, 15)
    .text(user.email, 50, 25)
    .text(user.phone, 50, 35);

  doc.setFont('Roboto', "bold")
    .setFontSize(18)
    .text(`${property.rooms ?? ""}-${t('__rooms')}. ${property.propertyType.label.toLowerCase()}, ${property.totalArea ?? ""} м²`, 10, 60)

  doc.autoTable({
    startY: 70,
    body: getRows(property).filter(row => !!row[1]),
    theme: 'plain',
    styles: {
      font: 'Roboto',
      fontStyle: 'normal',
    },

  });

  doc.setFont('Roboto', "normal")

  let x = 20;
  let y = doc.autoTable.previous.finalY + 10;
  const imageSize = 120;

  const { description } = property;
  const descriptionFontSize = 12;
  const descriptionWidth = 240;
  const descriptionLines = doc.splitTextToSize(description, descriptionWidth);

  doc.setFontSize(descriptionFontSize);
  descriptionLines.forEach(line => {
    doc.text(line, 10, y);
    y += 8;
    if (y > pageHeight) {
      doc.addPage();
      y = 10;
    }
  })

  const imagePromises = [];

  property.images.forEach(data => {
    const imageUrl = data.largeImage.startsWith('http') ? data.largeImage : (imagesPath + data.largeImage);
    const imagePromise = loadImage(imageUrl);
    imagePromises.push(imagePromise);
  });

  const images = await Promise.all(imagePromises);

  images.forEach((img) => {
    const expectedY = y + imageSize + 10;
    if (expectedY > pageHeight) {
      doc.addPage();
      y = 10;
    }

    const imgRatio = img.width / img.height;

    const width = imageSize * imgRatio;
    x = Math.ceil((pageWidth - width) / 2);
    doc.addImage(img, 'JPEG', x, y, width, imageSize);

    y += imageSize + 10;

  });

  return doc;
}

const getRows = (property) => {
  const formatter = property && new Intl.NumberFormat('ru-RU', {
    style: 'currency', currency: property.currency.label, maximumFractionDigits: 0,
  });
  return [
    [t('disctrict'), property.street?.cityRegion?.name],
    [t('array'), property.residentialComplex],
    [t('street'), property.street?.name],
    [t('houseNumber'), property.houseNumber],
    [t('space'), property.totalArea ? property.totalArea + " м²" : ""],
    [t('liveSpace'), property.livingArea ? property.livingArea + " м²" : ""],
    [t('kitchen'), property.kitchenArea ? property.kitchenArea + " м²" : ""],
    [t('floor'), property.floor],
    [t('_floors'), property.floorTotal],
    [t('price'), property.price ? formatter.format(property.price) : ""],
    [t('priceForM2'), property.totalArea ? formatter.format(property.price / property.totalArea) : ""],
  ];
}

function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = function (error) {
      reject(error);
    };
    img.src = src;
  });
}

