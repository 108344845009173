import ApiDefault from "./ApiDefault";

const PATH = 'crm/report';

export default class Report extends ApiDefault {

  async create(data) {
    return this.post(PATH, data);
  }

  async all(dateStart, dateEnd) {
    return this.post(PATH + "/table", { dateStart, dateEnd });
  }
}