import LoadingButton from "@mui/lab/LoadingButton";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from 'react';

export const SubmitResetGroup = ({
  addLabel,
  cancelLabel = null,
  disabled,
  handleClick,
  handleClose,
  loading
}) => {
  const { t } = useTranslation(["translation"]);

  return <div>
    <LoadingButton
      endIcon={<></>}
      color="primary"
      disabled={disabled}
      loading={loading}
      onClick={handleClick}
      sx={{
        display: 'block',
        margin: '0px auto',
        marginTop:"50px",
        width: '100%',
        maxWidth: '400px',
      }}
      type="submit"
      variant="contained"
    >
      {addLabel}
    </LoadingButton>
    <Button
      disabled={loading}
      type="reset"
      sx={{
        display: 'block',
        margin: '0px auto',
        mt: 2,
        width: '100%',
        maxWidth: '400px',
        color: (theme) => theme.palette.dark
      }}
      onClick={handleClose}
    >
      {cancelLabel !== null ? cancelLabel : t('cancelTitle')}
    </Button>
  </div>
}