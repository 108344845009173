import ApiDefault from "./ApiDefault";

const PATH = 'payment';

export default class Payment extends ApiDefault {

  async list(page) {
    return this.get(PATH + "/" + page);
  }

  async makePayment(data) {
    return this.put(PATH, data)
  }

  async checkStatus(id) {
    return this.get(PATH + "/" + id + '/status')
  }
}