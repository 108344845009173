import { LicenseInfo } from "@mui/x-license-pro";
import i18n from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import { store } from "./store";

LicenseInfo.setLicenseKey("YOUR_LICENSE_KEY");

i18n
    .use(Backend)
    .use(I18nextBrowserLanguageDetector)
    .init({
        fallbackLng: "ua",
        supportedLngs: ["ua", "ru"],
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
        detection: {
            order: ["localStorage", "navigator"],
            caches: ["localStorage"],
        },
        react: {
            wait: true,
        },
    });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <Suspense fallback={<></>}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Suspense>
        </I18nextProvider>
    </Provider>
);
