import { Box, Grid, Tabs, Typography, } from "@mui/material";
import { useSelector } from "react-redux";
import { AddButton } from "../buttons";
import { useTranslation } from "react-i18next";
import { Tab } from "components";
import React from "react";

export function PageHeader(
  {
    tabValue,
    tabSetValue,
    handleClickOpen,
    title,
    secondTabName,
    children,
  }) {
  const { t } = useTranslation(["translation", "form"]);
  const { user } = useSelector((x) => x.auth);

  return (
    <Grid
      item
      xs={12}
      sx={{
        position: "sticky",
        height: { xs: 64, xl: 80 },
        zIndex: 10,
      }}
      className={"content--header"}
    >
      <Box
        sx={{
          backgroundColor: "transparent",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          height: { xs: 64, xl: 80 },
          position: "relative",
        }}
      >
        <Typography variant="h2" sx={{ mr: 2 }}>
          {title}
        </Typography>
        {handleClickOpen !== undefined && (
          <AddButton onClick={handleClickOpen} disabled={!user.isCrmUser} />
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: { xs: 64, xl: 80, position: "absolute", right: 0 },
          }}
        >
          {children}
          {tabSetValue !== undefined && (
            <Tabs
              sx={{ display: "block", minHeight: "unset" }}
              value={tabValue}
              onChange={(e, value) => tabSetValue(value)}
            >
              <Tab
                label={t("active")}
                value={1}
              />
              <Tab
                label={secondTabName}
                value={0}
                sx={{ marginLeft: { xs: 2.8, xl: 3.5 } }}
              />
            </Tabs>
          )}
        </Box>
      </Box>
    </Grid>
  );
}
