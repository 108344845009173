import { Grid } from "@mui/material";
import React from "react";
import { CalendarWidget } from "../calendar";
import { ScrolledGrid } from "../ScrolledGrid";

export function Boards({ boards }) {

  return <>
    <Grid
      item
      xs={9}
      sx={{
        height: { xs: "calc(100vh - 64px)", xl: "calc(100vh - 84px)" },
      }}
    >
      <ScrolledGrid
        container
        columnSpacing={2.5}
        className="custom-scrollbar"
      >
        {boards}
      </ScrolledGrid>
    </Grid>
    <CalendarWidget />
  </>
}

