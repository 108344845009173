import CloseIcon from "@mui/icons-material/Close";
import { Box, Chip, MenuItem, Select, Typography } from "@mui/material";
import * as React from "react";

export function MultiSelect({ handleChange, onDelete, options, title, value }) {
  return <>
    <Typography variant="h4" sx={{ mb: 0.75, mt: {xs: 3, md:3.75} }}>
      {title}
    </Typography>
    <Select
      fullWidth
      multiple
      name="agents"
      onChange={handleChange}
      sx={{ mb: {xs: 4.4, xl:5.5} }}
      value={value}
      variant="filled"
      renderValue={(selected) => (<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {selected.slice(0, 3).map((agent, key) => {
          const label = agent.lastName + ' ' + agent.firstName.charAt(0).toUpperCase() + ". " + agent.middleName.charAt(0).toUpperCase() + '.';
          return (<Chip
            key={agent.id}
            label={label}
            onDelete={() => onDelete(agent)}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            sx={{ backgroundColor: colors[key] }}
            deleteIcon={<CloseIcon sx={{ width: 11, height: 11 }} />}
          />)
        })}
        {selected.length > 3 ? (<Chip
          key="total"
          label={"+" + (selected.length - 3)}
          sx={{ backgroundColor: colors.at(-1) }}
        />) : ("")}
      </Box>)}
      MenuProps={MenuProps}
    >
      {options.map((agent) => (<MenuItem key={agent.id} value={agent}>
        {agent.lastName} {agent.firstName} {agent.middleName}
      </MenuItem>))}
    </Select>
  </>
}

const colors = ["rgba(28, 119, 255, 0.08)", "rgba(255, 138, 91, 0.08)", "rgba(130, 107, 255, 0.08)", "rgba(251, 195, 67, 0.08)"];

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200,
    },
  },
};