import { Box, buttonClasses, menuItemClasses, Typography } from "@mui/material";
import { NestedDropdown } from 'mui-nested-menu';
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

export function DepartmentAgentSelector({ departments, setFilter }) {
  const { t } = useTranslation(["translation", "form"]);
  const { user } = useSelector(x => x.auth);
  const [type, setType] = useState('department');
  const [title, setTitle] = useState(user.isDirector ? t('departentsList') : t('myDepartment'));
  const [open, setOpen] = useState(false);

  const handlerClick = (type, id, title, head = null) => {
    setType(type)
    setTitle(title)
    setFilter({ type, id, head })
    setOpen(false)
  }
  const menuItemsData = {
    label: title, items: [],
  };
  const sx = theme => ({
    padding: "5px 10px 5px 2px",
    fontWeight: "500",
    fontSize: "20px",
    fontFamily: "Fixel Medium",
    lineHeight: "24px",
    margin: "0 10px",
    [`&.${menuItemClasses.root}:hover`]: {
      backgroundColor: theme.palette.primary.mainWithOpacity,
      color: theme.palette.primary.main,
    },
  });

  if (user.isDirector) {
    menuItemsData.items.push({
      label: t('departentsList'), callback: () => {
        setType('department');
        setTitle(t('departentsList'));
        setFilter(null);
        setOpen(false)
      },
      sx
    })
    menuItemsData.items.push({
      label: user.fullName,
      callback: () => {
        handlerClick('director', user.email, user.fullName)
      },
      sx
    })
  }

  departments.forEach(department => {
    if (department.id === 'requests') {
      return;
    }
    const newDepartment = {
      label: department.title,
      callback: () => handlerClick('department', department.id, department.title, department.head?.id),
      sx,
      rightIcon: <ArrowRightIcon />,
      items: [{
        label: t('chooseDepartment') + " " + department.title,
        callback: () => handlerClick('department', department.id, department.title, department.head?.id),
        sx
      }]
    }

    if (department.head) {
      const agent = department.head;
      const fullName = agent.lastName + " " + agent.firstName + " " + agent.middleName;

      const newLabel = {
        label: fullName,
        callback: () => handlerClick('agent', agent.id, fullName),
        sx
      }
      newDepartment.items.push(newLabel);
    }

    department.agents.forEach(agent => {
      if (agent.status === 0) {
        return;
      }
      const fullName = agent.lastName + " " + agent.firstName + " " + agent.middleName;


      const newLabel = {
        label: fullName,
        callback: () => handlerClick('agent', agent.id, fullName),
        sx
      }
      newDepartment.items.push(newLabel);
    })

    menuItemsData.items.push(newDepartment);
  })

  return <Box
    sx={{
      alignItems: "center",
      display: "flex",
      mr: { xs: 6, xl: 7.5 }
    }}
  >
    <Typography variant="text" sx={{color: "newGrey.dark", fontSize: "16px"}}>
      {type === "department" ? t('department') : t('agent')}:
    </Typography>
    <NestedDropdown
      menuItemsData={menuItemsData}
      MenuProps={{
        elevation: 3,
      }}
      onClick={() => setOpen(true)}
      ButtonProps={{
        endIcon: open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />,
        variant: 'text',
        sx: (theme) => ({
          color: "dark",
          fontWeight: "500",
          fontSize: "16px",
          fontFamily: "Fixel Medium",
          lineHeight: "24px",
          paddingLeft: { xs: "20px", xl: "27px" },
          [`&.${buttonClasses.root}:hover`]: {
            backgroundColor: "unset",
            color: theme.palette.primary.main,
          },
        }),
      }}
    />
  </Box>
}
