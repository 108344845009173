import { SnackbarProvider, useSnackbar } from 'notistack';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { messageActions } from "store";

function MyApp() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const message = useSelector(store => store.message);

  useEffect(() => {
    if (message.text) {
      enqueueSnackbar(message.text, { variant: message.type });
      dispatch(messageActions.clear());
    }
  }, [message, enqueueSnackbar, dispatch])

  return (
    <></>
  );
}

export default function Notification() {
  return (
    <SnackbarProvider maxSnack={3} preventDuplicate={true}>
      <MyApp />
    </SnackbarProvider>
  );
}
