import ClearIcon from "@mui/icons-material/Clear";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import React from 'react';
import { useTranslation } from "react-i18next";
import { FilterSelect } from "./CheckboxSelect";

export const RangeSelector = ({ initialState, isForm, options, placeholder, symbol, value, onChange }) => {
  const { t } = useTranslation(["translation", "form"]);

  const handleOptionChange = (event) => {
    onChange(event.target.value);
  };

  const handleInputFromChange = (event) => {
    const price = event.target.value.replace(/[^0-9]/g, '');
    const from = price ? Number.parseInt(price) : "";

    onChange({ ...value, from });
  };

  const handleInputToChange = (event) => {
    const price = event.target.value.replace(/[^0-9]/g, '');
    const to = price ? Number.parseInt(price) : "";

    onChange({ ...value, to });
  };

  const handleClear = () => onChange(initialState);

  return (
    <FilterSelect
      onChange={handleOptionChange}
      size="small"
      renderValue={(selected) => selected.from && selected.to
        ? `${t('from')} ${selected.from}${symbol} ${String(t('to')).toLocaleLowerCase()} ${selected.to}${symbol}`
        : selected.from ? `${t('from')} ${selected.from}${symbol}`
          : selected.to ? `${t('to')} ${selected.to}${symbol}` : ''}
      endAdornment={
        <ClearIcon
          fontSize="small"
          onClick={handleClear}
          sx={{ display: value.from || value.to ? "" : "none", cursor: "pointer" }}
        />
      }
      fullWidth
      sx={{
        mb: 0.5,
        ml: 0,
        mt: isForm ? 1 : 0,
        '& .MuiSelect-select .notranslate::after': placeholder ? {
          content: `"${placeholder}"`, opacity: 0.42,
        } : {},
        "& .MuiSelect-icon": { display: value.from || value.to ? 'none' : '' }
      }}
      value={!value.from && !value.to ? "" : value}
      variant={isForm ? "filled" : "outlined"}
    >
      {options.map((option, key) => <MenuItem key={key}
                                              value={{ from: option.from, to: option.to }}>{option.name}</MenuItem>)}
      <MenuItem onKeyDown={(e) => e.stopPropagation()}>
        <TextField
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{symbol}</InputAdornment>
          }}
          onClick={(e) => e.stopPropagation()}
          onChange={handleInputFromChange}
          placeholder={t('from')}
          value={value.from}
          variant="filled"
        />
      </MenuItem>
      <MenuItem onKeyDown={(e) => e.stopPropagation()}>
        <TextField
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">{symbol}</InputAdornment>
          }}
          onClick={(e) => e.stopPropagation()}
          onChange={handleInputToChange}
          placeholder={t('to')}
          value={value.to}
          variant="filled"
        />
      </MenuItem>
    </FilterSelect>
  );
};