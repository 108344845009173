import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from 'react';

const StyledButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "white",
  [`&:hover`]: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
  },
  [`&:disabled`]: {
    backgroundColor: theme.palette.grey.main,
  },
}));

export const Button = ({ children, disabled, onClick, className = "" }) => {
  return (
    <StyledButton
      className={className}
      disabled={disabled}
      onClick={onClick}
      sx={{
        fontSize: {xs: "16px", xl: "20px"},
        height: {xs: "32px", xl: "40px"},
        width: {xs: "32px", xl: "40px"}
    }}
    >
      {children}
    </StyledButton>
  );
};
