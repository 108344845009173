import { Box, Dialog, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { SubmitResetGroup } from "components";
import { getLinkForMessenger } from "helpers";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CloseDialog } from "../CloseDialog";

export default function SendPresentationDialog({ handleClose, id, open }) {
  const { t } = useTranslation(["translation", "form"]);
  const [value, setValue] = useState('telegram');
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleSubmit = () => {
    const url = window.location.host + "/presentation/" + id;
    const link = getLinkForMessenger(value, url);
    link && window.open && window.open(link + url, '_blank').focus();
    handleClose();
  };
  return <Dialog open={open} onClose={handleClose} maxWidth="lg">
    <CloseDialog handleClose={handleClose} />
    <Box sx={{ margin: "46px 148px 100px 148px", alignItems: "center" }}>
      <Typography variant="h1" sx={{ fontWeight: "bold", mb: 2.5, mt: 7.5 }}>
        {t("sendPresentation")}
      </Typography>
      <Typography variant="h5" sx={{ mt: 2.5, mb: 6 }}>
        {t("sendPresentationType")}
      </Typography>
      <Box>
        <FormControl>
          <RadioGroup
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel value="telegram" control={<Radio />} label="Telegram" />
            <FormControlLabel value="viber" control={<Radio />} label="Viber" />
            <FormControlLabel value="whatsapp" control={<Radio />} label="WhatsApp" />
            <FormControlLabel value="link" control={<Radio />} label={t("copyLink")} />
          </RadioGroup>
        </FormControl>
      </Box>
      <SubmitResetGroup addLabel={t('send')} handleClose={handleClose} handleClick={handleSubmit} />
    </Box>
  </Dialog>
}