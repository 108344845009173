import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Button, Dialog, Typography } from "@mui/material";
import { CloseDialog, SubmitResetGroup } from "components/index";
import { apartmentApi, presentationApi } from "helpers";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ApartmentAccordion from "./ApartmentAccordion";
import SendPresentationDialog from "./SendPresentationDialog";

export function PresentationDialog({ apartments, clientId, handleClose, open }) {
  const { t } = useTranslation(["translation", "form"]);
  const [apartmentsData, setApartmentsData] = useState([]);
  const [errors, setErrors] = useState([])
  const [formData, setFormData] = useState([])
  const [presentationId, setPresentationId] = useState()
  const [isPreview, setIsPreview] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openSend, setOpenSend] = useState(false)
  const formsRef = useRef([]);
  const mainFormRef = useRef(null);
  formsRef.current = formsRef.current.slice(0, apartmentsData.length);

  useEffect(() => {
    apartmentApi.getApartment(apartments).then(({ result }) => {
      setApartmentsData(result);
    })
  }, [apartments]);

  useEffect(() => {
    if (!formData.length) {
      return;
    }

    setErrors(apartmentsData.filter(apartment =>
      formData.filter(formApartment => formApartment.id === apartment.id).length === 0
    ).map(apartment => apartment.id));

    if (formData.length === apartmentsData.length) {
      const data = apartmentsData.map(apartment => formData.find(form => form.id === apartment.id));
      setLoading(true);
      presentationApi.create({
        apartments: data,
        clientId,
        presentationId
      }).then(({ id }) => {
        setPresentationId(id);
        setLoading(false);
        if (id && isPreview) {
          window.open && window.open('/presentation/' + id, '_blank').focus();
        }

        if (id && !isPreview) {
          setOpenSend(true)
        }
      });
    }
  }, [formData])
  const handleDrag = (dragIndex, hoverIndex) => {
    const newData = [...apartmentsData];
    newData.splice(hoverIndex, 0, newData.splice(dragIndex, 1)[0])
    setApartmentsData(newData);
  }

  const handleSubmit = (data) => {
    setFormData((prevFormData) => {
      const filtered = prevFormData.filter(apartment => apartment.id !== data.id);
      return [...filtered, data]
    })
  };

  const handlePreview = () => {
    setIsPreview(true);
    mainFormRef.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  }
  return (<Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth={true}>
    <CloseDialog handleClose={handleClose} />
    <Box sx={{ margin: "46px 148px 100px 148px", alignItems: "center", position: "relative" }}>
      <Typography variant="h1" sx={{ fontWeight: "bold", mb: 2.5, mt: 7.5 }}>
        {t("sendPresentation")}
      </Typography>
      {apartments.length > 1 && <Typography variant="h5" sx={{ mt: 2.5, mb: 6 }}>
        {t("choosedObjects")}
      </Typography>}
      <form ref={mainFormRef} onSubmit={(e) => {
        e.preventDefault();
        formsRef.current.forEach(form => form.prepareData())
      }}>
        {apartmentsData.map((apartment, index) => (<ApartmentAccordion
          key={index}
          apartment={apartment}
          error={errors.includes(apartment.id)}
          handleDrag={handleDrag}
          handleSubmit={handleSubmit}
          index={index}
          refProp={el => formsRef.current[index] = el}
        />))}
        <SubmitResetGroup addLabel={t('send')} handleClose={handleClose} loading={loading}
                          handleClick={() => setIsPreview(false)} />
        <Box sx={{ bottom: 0, position: "absolute", right: 0 }}>
          <Button
            color="text"
            disabled={loading}
            onClick={() => handlePreview()}
            variant="text"
            startIcon={<VisibilityOutlinedIcon />}
          >
            {t("showPreview")}
          </Button>
        </Box>
      </form>
    </Box>
    {openSend && <SendPresentationDialog id={presentationId} open={openSend} handleClose={() => {
      setOpenSend(false);
      handleClose();
    }} />}
  </Dialog>);
}