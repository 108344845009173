import { Grid } from "@mui/material";
import { apartmentApi } from "helpers";
import React from 'react';
import { useTranslation } from "react-i18next";
import { AddressAutocomplete } from "./AddressAutocomplete";
import { LabeledAsyncSelect } from "./LabeledAsyncSelect";
import { LabeledInput } from "./LabeledInput";
import { LabeledSelect } from "./LabeledSelect";

export function MainInformationBlock({ disabled, formik }) {
  const { t } = useTranslation(["translation", "form"]);

  return <Grid
    container
    rowSpacing={1}
    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
  >
    <Grid item xs={6}>
      <LabeledSelect
        label={t('operationType')}
        disabled={disabled}
        field="operationType"
        formik={formik}
        options={[{ value: 1, name: t('rent') }, { value: 0, name: t('sell') }]}
        placeholder={t('chooseOperationType')}
      />
      <LabeledSelect
        label={t('rentType')}
        disabled={disabled}
        field="propertyType"
        formik={formik}
        options={[{ value: 0, name: t('flat') }, { value: 1, name: t('commercial') }, {
          value: 2,
          name: t('house')
        }, { value: 3, name: t('ground') }]}
        placeholder={t('chooseRentType')}
      />
      <AddressAutocomplete
        disabled={disabled}
        field="state"
        formik={formik}
        label={t('region')}
        placeholder={t('chooseRegion')}
        required
      />
      <AddressAutocomplete
        disabled={disabled}
        field="city"
        formik={formik}
        label={t('city')}
        placeholder={t('chooseCity')}
        required
      />
      <AddressAutocomplete
        disabled={disabled}
        field="cityRegion"
        formik={formik}
        label={t('district')}
        placeholder={t('chooseDistrict')}
      />
    </Grid>
    <Grid item xs={6}>
      <AddressAutocomplete
        disabled={disabled}
        field="citySubregion"
        formik={formik}
        label={t('microDistrict')}
        placeholder={t('chooseMicroDisrict')}
      />
      <AddressAutocomplete
        disabled={disabled}
        field="street"
        formik={formik}
        label={t('street')}
        placeholder={t('chooseStreet')}
        required
      />
      <LabeledInput
        disabled={disabled}
        field="houseNumber"
        formik={formik}
        label={t('houseNumber')}
        placeholder={t('typeHouseNumber')}
        required
      />
      <AddressAutocomplete
        disabled={disabled}
        field="residentialComplex"
        formik={formik}
        label={t('complex')}
        placeholder={t('chooseComplex')}
      />
      <Grid
        container
        columnSpacing={3}>
        <Grid item xs={8}>
          <LabeledInput
            disabled={disabled}
            field="price"
            formik={formik}
            label={t('price')}
            placeholder={t('objectType')}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <LabeledSelect
            disabled={disabled}
            label={t('currency')}
            field="currency"
            formik={formik}
            options={[{ value: 0, name: "USD" }, { value: 1, name: "UAH" }]}
            placeholder={t('chooseCurrency')}
          />
        </Grid>
      </Grid>

    </Grid>
  </Grid>;
}

export function DetailInformationBlock({ formik }) {
  const { t } = useTranslation(["translation", "form"]);

  return <Grid
    container
    rowSpacing={1}
    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
  >
    <Grid item xs={6}>
      <LabeledInput
        field="rooms"
        formik={formik}
        label={t('roomCount')}
        placeholder={t('typeRoomCount')}
        required
      />
      <LabeledInput
        endAdornment="м&#178;"
        field="totalArea"
        formik={formik}
        label={t('space')}
        placeholder={t('typeSpace')}
        required
      />
      <LabeledInput
        endAdornment="м&#178;"
        field="livingArea"
        formik={formik}
        label={t('liveSpace')}
        placeholder={t('typeLiveSpace')}
        required
      />
      <LabeledInput
        endAdornment="м&#178;"
        field="kitchenArea"
        formik={formik}
        label={t('kitchenSpace')}
        placeholder={t('chooseKitchenSpace')}
        required
      />
      <LabeledInput
        field="floor"
        formik={formik}
        label={t('floor')}
        placeholder={t('typeFloor')}
        required
      />
      <LabeledInput
        field="floorTotal"
        formik={formik}
        label={t('houseFloor')}
        placeholder={t('typeHouseFloor')}
        required
      />
      <LabeledAsyncSelect
        async={() => apartmentApi.repairTypes()}
        field="repair"
        formik={formik}
        label={t('repair')}
        placeholder={t('typeRepair')}
        required
      />
    </Grid>
    <Grid item xs={6}>
      <LabeledAsyncSelect
        async={() => apartmentApi.ownerTypes()}
        field="owner"
        formik={formik}
        label={t('owner')}
        placeholder={t('typeOwner')}
        required
      />
      <LabeledInput
        field="ownerName"
        formik={formik}
        label={t('ownerName')}
        placeholder={t('typeOwnerName')}
        required
      />
      <LabeledInput
        field="phone"
        formik={formik}
        label={t('typeOwnerContact')}
        placeholder={t('typeOwnerNumber')}
        required
      />
      <LabeledInput
        field="description"
        formik={formik}
        label={t('description')}
        multiline
        placeholder={t('objectDesctipyion')}
        rows={5}
      />
      <LabeledInput
        field="serviceInformation"
        formik={formik}
        label={t('staffInfo')}
        multiline
        placeholder={t('typeStaffInfo')}
        rows={5}
      />
    </Grid>
  </Grid>;
}
