import { Radio as MuiRadio, radioClasses } from "@mui/material";
import { ReactComponent as UncheckedIcon } from "assets/radio_unchecked.svg";
import { ReactComponent as CheckedIcon } from "assets/radio_checked.svg";

export const Radio = ({ children, checked, ...props }) => (
  <MuiRadio
    sx={{ color: "newGrey.light", [`&.${radioClasses.checked}`]: {color: "background.paper"} }}
    icon={<UncheckedIcon />}
    checkedIcon={<CheckedIcon />}
    checked={checked}
    {...props}
  >
    {children}
  </MuiRadio>
);
