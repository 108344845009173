import { Box, Container } from "@mui/material";
import Menu from "../modules/main/menu/Menu";
import React from 'react';

export function MainContainer({ children, paddingTop = 0 }) {
  return <Container style={{
    minHeight: "100%",
    display: "flex",
    padding: 0,
    maxWidth: "100%",
  }}>
    <Box
      flexDirection="row"
      className="main"
      sx={{ width: "100%", minHeight: "100%" }}
    >
      <Box
        display="flex"
        flexDirection="row"
        sx={{ height: { xs: "calc(100% - 64px)", xl: "calc(100% - 80px)" } }}
      >
        <Box sx={{ width: { xs: 64, xl: 80 } }}>
          <Menu />
        </Box>
        <Box sx={{ width: { xs: "calc(100% - 64px)", xl: "calc(100% - 80px)" } }}>
          {children}
        </Box>
      </Box>
    </Box>
  </Container>
}
