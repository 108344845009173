import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { getAddress } from "helpers";
import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import ApartmentInformation from "./ApartmentInformation";

export default function ApartmentAccordion({ apartment, error, handleDrag, handleSubmit, index, refProp }) {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: "presentationApartments", collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    }, hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      handleDrag(dragIndex, hoverIndex);
      item.index = hoverIndex
    },
  })

  const [, drag] = useDrag({
    type: "presentationApartments", item: () => {
      return { id: apartment.id, index }
    }, collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))
  const style = error ? { border: "1px solid red" } : {};
  return <Accordion ref={ref} data-handler-id={handlerId} key={index}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id={apartment.id + "-header"}
      sx={style}
    >
      <DragIndicatorIcon fontSize="small" sx={{ color: "#141414", mr: 2 }} /><Typography
      sx={{}}>{getAddress(apartment)}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <ApartmentInformation apartment={apartment} handleSubmit={handleSubmit} key={index + apartment.id}
                            ref={refProp} />
    </AccordionDetails>
  </Accordion>;
}