import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Button } from "./Button";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from 'react';

const StyledButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "white",
  [`&:hover`]: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
  },
  [`&:disabled`]: {
    backgroundColor: theme.palette.grey.main,
  },
  boxShadow: '0px 7px 12px rgba(0,0,0,0.1)',
}));

export const AddButtonShadow = ({ onClick, disabled }) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled}>
      <AddOutlinedIcon style={{
        width: 24,
        height: 24,
      }} />
    </StyledButton>
  );
};
