export default {
  palette: {
    mode: 'dark',
    background: {
      paper: '#1E2331',
      black: '#DCDCDC',
      white:'#1E2331'
    },
    primary: {
      light: "#7ad3fb",
      main: "#59C8FA",
      dark: "#1C77FF",
      contrastText: "#fff",
      mainWithOpacity: "rgba(89, 200, 250, 0.08)",
      success: "rgb(56, 142, 60)"
    },
    grey: {
      main: "#1E2331",
      light: "#1C1D27"
    },
    text: {
      main: "#1C1D27",
      white: "#1E2331",
    },
    newGrey: {
      dark: "#CBCBCB",
      medium: "#909598",
      medium2: "#85878A",
      light: "#525252",
      veryLight: "#1C1D27",
    },
    dark: "#DCDCDC",
    black: "#C1C1C1",
  },
  breakpoints: {
      values: {
          xs: 0,
          sm: 600,
          md: 810,
          lg: 1200,
          xl: 1536,
      },
  },
  typography: {
    fontFamily: 'Fixel Regular',
    h1: {
      fontFamily: 'Fixel Bold',
      fontSize: 36,
      lineHeight: "44px",
      color: '#F5F5F5',
    },
    h2: {
      fontFamily: 'Fixel Bold',
      lineHeight: { xs: 28, sm: 34, xl: 40 },
      '@media (min-width:0px)': {
        fontSize: 22,
      },
      '@media (min-width:1536px)': {
        fontSize: 28,
      },
      color: '#F5F5F5',
    },
    h3: {
      fontFamily: 'Fixel Bold',
      fontSize: 20,
      lineHeight: { xs: 28, sm: 28 },
      color: '#F5F5F5',
      fontWeight: '700',
    },
    h4: {
      fontFamily: 'Fixel Regular',
      fontSize: 14,
      lineHeight: "22px",
      fontWeight: '500',
      color: '#F5F5F5',
    },
    h5: {
      fontFamily: 'Fixel Regular',
      lineHeight: "22px",
      color: "#F5F5F5",
      '@media (min-width:0px)': {
        fontSize: 12,
      },
      '@media (min-width:1536px)': {
        fontSize: 14,
      }
    },
    h6: {
      fontFamily: 'Fixel Bold',
      fontSize: 12,
      lineHeight: "18px",
      color: '#F5F5F5',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: "none",
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          backgroundColor: "#1C1D27",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#1C1D27",
          '&.Mui-focused, &:hover': {
            backgroundColor: '#1C1D27'
          },
        },
        input: {
          padding: "12px",
          fontFamily: 'Fixel Regular',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '22px',
          height: '22px',
          color: '#DCDCDC',
        },
        placeholder: {
          color: "#8B9398",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root': {
            padding: "12px 39px 12px 12px",
          },
        }
      }
    }
  },
};
