import { styled } from '@mui/material/styles';
import { listItemIconClasses, Menu, menuClasses, menuItemClasses } from "@mui/material";

export function ContextMenu({children, ...props}) {
  return <StyledMenu {...props}>{children}</StyledMenu>
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  [`& .${menuClasses.paper}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.dark,
    color: theme.palette.text.white,
    [`& .${menuClasses.list}`]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
    [`& .${menuItemClasses.root}`]: {
      fontSize: 12,
      lineHeight: "18px",
      padding: "10px 40px 10px 20px",
      [`& .${listItemIconClasses.root}`]: {
        color: theme.palette.text.white,
        // marginRight: theme.spacing(1.5),
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));