import { IconButton } from "@mui/material";
import React, { useEffect, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from "@mui/material/styles";

export function ScrollUp() {
  const [visible, setVisible] = useState(false);
  const handleScroll = () => {
    const yPosition = Math.ceil(document.querySelector('#main-container').scrollTop);
    const minPositionForShow = 100;

    setVisible(yPosition > minPositionForShow);
  };

  useEffect(() => {
    document.querySelector('#main-container').addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const onClickHandler = (event) => {
    event.preventDefault();
    document.querySelector('#main-container').scrollTo({ top: 0, behavior: 'smooth' });
  }

  return visible &&
    <StyledIconButton
      className={'scroll-up'}
      onClick={onClickHandler}
      sx={{
        width: { xs: "38px", xl: "48px" },
        height: { xs: "38px", xl: "48px" },
        backgroundColor: "newGrey.light",
        color: "dark",
        position: "fixed",
        right: { xs: "32px", xl: "40px" },
        bottom: { xs: "32px", xl: "40px" },
        transition: "all 0.2s ease-out"
      }}
    >
      <KeyboardArrowUpIcon />
    </StyledIconButton>
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`&:hover`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
  },
}));